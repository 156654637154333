import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const AndroidApp = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "play-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <a
      class="d-flex justify-content-center"
      target="_blank"
      href="
              https://play.google.com/store/apps/details?id=com.mytaskeyapp
            "
    >
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt={"Play Store to mytaskey app"}
      />
    </a>
  )
}

AndroidApp.propTypes = {}

export default AndroidApp
