import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const IOSApp = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "app_store.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <a
      class="d-flex justify-content-center"
      target="_blank"
      href="
      https://apps.apple.com/us/app/id1492737953
            "
    >
      <Img
        style={{ width: 128, height: 128, margin: "auto" }}
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt={"Play Store to mytaskey app"}
      />
    </a>
  )
}

IOSApp.propTypes = {}

export default IOSApp
