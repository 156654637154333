import React from "react"
import { injectIntl, Link, intlShape } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { navigate, useStaticQuery, graphql } from "gatsby"

import "../style/global.scss"
import Layout from "../components/Layout"
import AndroidApp from "../components/Apps/android"
import IOSApp from "../components/Apps/ios"

const SuccessPage = ({ location, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "play-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <div class="main-w3layouts wrapper">
        <h1>{intl.formatMessage({ id: "download-app" })}</h1>

        <div class="main-agileinfo">
          <div class="agileits-top">
            <h4 class="text-center">
              {intl.formatMessage({ id: "get-it-from-playstore" })}
            </h4>

            <div class="ecard-info-container flex-column p-2 pt-2 justify-content-between">
              <div class="pt-1 pb-1 card-item">
                <AndroidApp />
                <IOSApp />
              </div>
            </div>
          </div>
        </div>
        <p style={{ color: "white", textAlign: "center", marginTop: 10 }}>
          ©2020 mytaskey.com |{" "}
          {intl.formatMessage({ id: "all_rights_reserved" })}.
        </p>
      </div>
    </Layout>
  )
}

SuccessPage.propTypes = {
  location: PropTypes.object,
  intl: intlShape,
}

export default injectIntl(SuccessPage)
